@import "../../../public/assets/scss/default/variables";
/*--------------------
Footer Styles 
----------------------*/

// For New Footer
.bg-theme-gradient {
  background: linear-gradient(
    120deg,
    #1c99fe 20.69%,
    #7644ff 50.19%,
    var(--color-primary) 79.69%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color-primary)', endColorstr='var(--color-secondary)',GradientType=1 );
}

.im-hover {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    @extend .bg-theme-gradient;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    @extend %transition;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
}

.footer-wrapper {
  position: relative;
  @media #{$sm-layout} {
    text-align: left;
  }
  @media #{$large-mobile} {
    text-align: center;
  }
}

.footer-style-01 {
  .footer-link {
    h4 {
      color: $white;
      font-weight: 900;
      margin-bottom: 20px;
      font-size: 20px;
      @media #{$sm-layout} {
        margin-bottom: 14px;
      }
    }
    ul {
      &.ft-link {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          color: $white;
          font-size: 16px;
          a {
            @extend %transition;
            @extend .im-hover;
            color: var(--color-gray);
            font-size: 16px;
            padding: 5px 0;
            &:hover {
              font-weight: 800;
            }
          }

          & + li {
            margin-top: 5px;

            @media #{$sm-layout} {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  .ft-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .logo {
      margin-bottom: 16px;
      text-align: center;
      @media #{$lg-layout}, #{$md-layout} {
        text-align: left;
      }
    }
    p {
      color: var(--color-gray);
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 1px;
      a {
        color: $theme-color;
        @extend .im-hover;
        &:hover {
          color: $white;
        }
      }
    }
  }
}

.footer-style-2,
.footer-area,
.footer-style-3 {
  .logo {
    a {
      display: block;
      img {
        max-height: 86px;
      }
    }
  }
}

.address {
  color: white;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  @media #{$lg-layout}, #{$md-layout}, #{$sm-layout} {
    text-align: left;
  }
  @media #{$large-mobile}, #{$small-mobile} {
    text-align: center;
  }
}

.right-col-devices {
  @media #{$md-layout}, #{$sm-layout} {
    transform: translateX(25%);
  }
  @media #{$large-mobile}, #{$small-mobile} {
    transform: none;
  }
}

.social-icons {
  justify-content: start;
  @media #{$large-mobile}, #{$small-mobile} {
    justify-content: center;
    margin-top: 40px;
  }
}

/* Copyright Area  */
.copyright-text {
  color: $white;
  text-align: center;

  @media #{$sm-layout} {
    position: static;
    bottom: 0;
    padding-top: 33px;
  }

  @media #{$md-layout} {
    position: static;
    bottom: 0;
    padding-top: 33px;
  }

  p {
    color: var(--color-gray);
    font-size: 14px;
    opacity: 0.75;
  }
}
