/*====================
    About Area 
======================*/

.rn-finding-us-area {
  .inner {
    position: relative;
    padding-left: 240px;
    padding-top: 110px;
    padding-bottom: 110px;

    @media #{$lg-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    @media #{$md-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    @media #{$sm-layout} {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
    }
    .content-wrapper {
      .content {
        background: rgba(0, 91, 170, .98);
        background-image: url(/assets/images/pattern-6.png);
        background-position: center;
        padding: 120px 100px;
        position: relative;
        z-index: 2;
        width: 800px;

        @media #{$lg-layout} {
          padding: 60px 50px;
          width: 100%;
        }

        @media #{$md-layout} {
          padding: 60px 50px;
          width: 100%;
        }

        @media #{$sm-layout} {
          padding: 30px 30px;
          width: 100%;
        }

        h4 {
          color: #ffffff;
          font-size: 36px;
          font-weight: 500;
          margin-bottom: 20px;
          @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 12px;
          }
        }
        p {
          font-size: 18px;
          color: #ffffff;
          font-weight: 300;
          margin-bottom: 41px;
          padding-right: 10%;
          @media #{$sm-layout} {
            margin-bottom: 24px;
            padding-right: 0;
          }
        }
      }
    }
    .thumbnail {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;

      @media #{$lg-layout} {
        position: static;
        height: 100%;
      }

      @media #{$md-layout} {
        position: static;
        height: 100%;
      }

      @media #{$sm-layout} {
        position: static;
        height: 100%;
      }
      // img {
      //   border-radius: 0;
      //   @media #{$lg-layout} {
      //     border-radius: 0;
      //   }
      //   @media #{$md-layout} {
      //     border-radius: 0;
      //   }
      //   @media #{$sm-layout} {
      //     border-radius: 0;
      //   }
      // }
    }
  }
}

.about-inner {
  @media #{$md-layout} {
    padding-top: 60px;
  }
  @media #{$sm-layout} {
    padding-top: 60px;
  }
  .section-title {
    h2 {
      &.title {
        margin-bottom: 8px;
      }
    }
    p {
      &.description {
        font-weight: 300;
        color: #717173;
        a {
          color: rgba(29, 29, 36, 1);
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
}

.about-wrapper {
  .thumbnail {
    position: relative;
    z-index: 2;
    img {
      border-radius: 0px;
    }
  }
}

.about-position-top {
  @media #{$lg-layout} {
    padding-top: 120px;
  }
  @media #{$md-layout} {
    padding-top: 80px;
  }
  @media #{$sm-layout} {
    padding-top: 60px;
  }
  .thumbnail {
    margin-top: -129px;
    @media #{$lg-layout} {
      margin-top: 0;
    }
    @media #{$laptop-device} {
      margin-top: -89px;
    }
    @media #{$md-layout} {
      margin-top: 50px;
    }
    @media #{$sm-layout} {
      margin-top: 30px;
    }
  }
}

.about-us-list {
  margin-top: 15px;
  h3 {
    &.title {
      font-size: 30px;
      @extend %fontWeight500;
      margin-bottom: 20px;
      @media #{$sm-layout} {
        font-size: 26px;
        margin-bottom: 8px;
      }
    }
  }
  p {
    font-weight: 300;
    color: rgba(29, 29, 36, 0.75);
  }
}

.rn-content-box-style--1 {
  .content {
    text-align: left;
    padding: 0 120px;
    @media #{$md-layout} {
      padding: 0 60px;
    }
    @media #{$sm-layout} {
      padding: 0 40px;
    }
    p {
      &.subtitle {
        color: $heading-color;
        font-size: 16px;
        font-weight: 400;
        background: rgba(255, 26, 80, 0.03);
        padding: 8px 25px;
        display: inline-block;
        border-radius: 4px;
        span {
          color: $theme-color;
        }
      }
    }
    h2 {
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 36px;
      line-height: 50px;
      font-weight: 500;
      @media #{$lg-layout} {
        margin-top: 7px;
      }
      @media #{$md-layout} {
        margin-top: 7px;
      }
      @media #{$sm-layout} {
        margin-top: 7px;
      }
    }
    p {
      color: rgba(29, 29, 36, 0.75);
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.rn-contact-page {
  background-image: url(/assets/images/pattern-6.png);
}

@media (max-width: 991px) { // Homepage About overlay swap columns up to 991px
  .col-rev-mobile {
    flex-direction: column-reverse;
  }
}
