@import "../../../public/assets/scss/default/variables";

.policy-hero {
  background-color: $theme-color;
  top: 100px;
}

.policy-hero::before {
  background-image: url(/assets/images/pattern-5.png);
}

.policy-heading {
  color: white;
}
