@import "../../../../public/assets/scss/default/variables";

.wrapper {
    align-items: flex-end;
    flex-direction: column-reverse;
}

.title {
    color: $white;
    font-size: 75px;
    font-weight: 900;
    line-height: 90px;

    &-column {
        @extend .title;
        line-height: 75px;
    }
}
@media #{$large-mobile} {
    .title {
        font-size: 35px;
        line-height: 40px;
    }
  }