@import "../../../../public/assets/scss/default/variables";

.capability-title {
  @media #{$large-mobile} {
      font-size: 27px;
      font-weight: 900;
  }
}

@media #{$md-layout}, #{$sm-layout} {
  .flex-col-rev {
    flex-direction: column-reverse;
  }
}
