@import "../../../../public/assets/scss/default/variables";

.title {
  font-size: 40px;
  line-height: 1;
}

.policy-copy {
  padding: 0 !important;
}
