@import "../../../public/assets/scss/default/variables";

.wrapper {
  background-color: $theme-color;
  border-radius: 50%;
  color: #fff;
  font-family: $body-font;
  height: 2.5em;
  position: relative;
  width: 2.5em;

  border: 1px solid black;
}

.text {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}
