@import "../../../public/assets/scss/default/variables";

.link {
    color: $white !important;
    text-decoration: underline;
    
    &:hover {
        font-weight: 600 !important;
        text-decoration: underline;
    }
}