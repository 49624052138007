@import "../../../public/assets/scss/default/variables";

.parallax-bg-image {
  height: auto;
  width: 100%;
}

.parallax-logo {
  margin-bottom: 90px;
  width: 450px;
}

.parallax-text {
  font-size: 60px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.4074;
}
