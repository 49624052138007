@import "../../../../public/assets/scss/default/variables";

.policy-link {
    color: $theme-color !important;
    text-decoration: underline;
    
    &:hover {
        font-weight: 600 !important;
        text-decoration: underline;
    }
}