@import "../../../public/assets/scss/default/variables";

.social-share-inner {
  margin: 0 15px 0 25px;
}

@media #{$sm-layout} {
  .social-share-inner {
    display: none;
  }
}

ul.social-share li a {
  color: $theme-color !important;
}
// Path that changes
.header-area.header--fixed.default-color.sticky
  .mainmenunav
  ul.social-header
  li
  a {
  color: $theme-color !important;
}

a ,
button {
    &.rn-btn {
        padding: 0 23px;
        height: 40px;
        display: inline-block;
        line-height: 34px;
        border: 2px solid $theme-color;
        border-radius: none;
        font-size: 14px;
        position: relative;
        z-index: 2;
        // font-family: Montserrat;
        color: $theme-color;
        letter-spacing: 0.2px;
        @extend %transition;
        text-transform: uppercase;
        &:hover {
            border: 2px solid $theme-color;
            transform: translateY(-3px);
            color: #ffffff;
            background: $theme-color;
        }
        &.btn-white {
            border: 2px solid #ffffff;
            color: $theme-color;
            background-color: $white;
            &:hover {
                font-weight: 800;
            }
        }
    }
}
